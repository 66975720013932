/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}


